.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.projects-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.projects-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.projects-social-icon:hover {
	color: var(--primary-color);
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 60px;
	}

	.projects-project {
		width: calc(100% / 2);
	}
}
